import * as React from "react";

import Layout from "../../components/Layout";
import PriceRoll from "../../components/PriceRoll";

export default class PriceIndexPage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout>
        <div className="columns" style={{ maxWidth: "100%", margin: "0"}}>
          <div className="column is-4" style={{ backgroundImage: `url('../img/DSC01162.jpg')`, height: "88vh", backgroundSize: "cover", margin: "auto"}} ></div>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <PriceRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
